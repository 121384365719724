<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <PageDeveloperUploadExcelCompaniesSales />
        <ComponentDeveloperCompaniesSalesList v-if="showComponent" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import PageDeveloperUploadExcelCompaniesSales from '../../views/developer/PageDeveloperUploadExcelCompaniesSales.vue';
import ComponentDeveloperCompaniesSalesList from '../../components/developer/clean/ComponentDeveloperCompaniesSalesList.vue';
export default {
    components: {
        PageDeveloperUploadExcelCompaniesSales,
        ComponentDeveloperCompaniesSalesList,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        data: null,
        dataHeader: [
        ],
        companyId: 99,
        isPending: false,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        showComponent: true,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {

        },

        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>